import React, {Suspense} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Loader from "./constant/Loader";

const HomePage = React.lazy(() => import("./layout/pages/HomePage/HomePage"));
const NotFound = React.lazy(() =>
	import("./layout/pages/NotFoundPage/NotFoundPage")
);

export default function App() {
	return (
		<div>
			<Router>
				<Suspense fallback={<Loader />}>
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>
			</Router>
		</div>
	);
}
